.m_controaler {
    position: fixed;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 10px;
    background-color: var(--main-color);
}

.animait-icone {
    width: 18px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.animait-icone span {
    display: block;
    width: 2px ;
    height: 50%;
    background-color: var(--second-color);
}

.animait-icone span.run-animation-one {
    animation: animait-span-one 1.3s alternate infinite;
}
.animait-icone span.run-animation-two {
    animation: animait-span-two 1s alternate infinite;
}
.animait-icone span.run-animation-three {
    animation: animait-span-three 0.8s alternate infinite;
}
.animait-icone span.run-animation-four {
    animation: animait-span-four 0.6s alternate infinite;
}
.song-details > div img {
    width: 50px;
    height: 40px;
    margin-right: 15px !important;
}
.item-controlar i {
    margin-left: 25px !important;
    color: white;
    cursor: pointer;
    font-size: 18px;
}

.thumb-wrapper {
    margin: 0 5px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#thumb,
#thumb-volume{
    width: 100%;
    opacity: 0;
    position: relative;
    cursor: pointer;
    z-index: 555;
}
 .thumb-ui,
 .thumb-ui-full-width {
    height: 3px;
    width: 45% ;
    display: block;
    background-color: var(--second-color);
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-40%);
    cursor: pointer;
    z-index: 10;
    transition: all ease-in-out 1s;
}
 .thumb-ui::after {
    content: "";
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: inherit;
 }
 .thumb-ui-full-width {
    background-color: #172136;
    width: 100%;
    z-index: 5;
}
.thumb-cricle {
    background-color: transparent;
    border: 2px solid var(--second-color);
    display: block;
    width: 20px;
    height: 20px;
    z-index: 15;
    position: absolute;
    left: 45% ;
    top: 40%;
    transform: translate(-40% , -50%);
    border-radius: 50%;
    cursor: pointer;
    transition: all ease-in-out 1s;
}
.thumb-wrapper time {
    margin: 0 20px !important;
    color: white;
}



@keyframes animait-span-one {
    0% {
        height: 20%;
    }
    30% {
        height: 30%;
    }
    60% {
        height: 60%;
    }
    100% {
        height: 20%;
    }
}
@keyframes animait-span-two {
    0% {
        height: 20%;
    }
    30% {
        height: 50%;
    }
    60% {
        height: 70%;
    }
    100% {
        height: 20%;
    }
}
@keyframes animait-span-three {
    0% {
        height: 15%;
    }
    30% {
        height: 36%;
    }
    60% {
        height: 70%;
    }
    100% {
        height: 15%;
    }
}
@keyframes animait-span-four {
    0% {
        height: 45%;
    }
    30% {
        height: 16%;
    }
    60% {
        height: 20%;
    }
    100% {
        height: 65%;
    }
}

/* 
    =================================================================== Responsive ========================================================================================================
*/

@media (max-width: 991px) {
    .item-controlar {
        width: 100% !important;
        text-align: center;
        margin-bottom: 10px;
    }
    .thumb-wrapper {
        width: 75% !important;
    }
    .volume-controlar {
        width: 20% !important;
    }
}

@media (max-width: 768px) {
    .thumb-wrapper {
        width: 100% !important;
    }
        .volume-controlar {
        width: 60% !important;
    }
}